<template>
  <div>
    <!-- <h6 class="heading-small text-muted mb-4 mt-4">Leads</h6> -->
    <el-table
      :data="venueLeads"
      row-key="id"
      id="venue_leads_data_table"
      header-row-class-name="thead-light"
    >
      <el-table-column label="Date" prop="created_at">
        <template v-slot="{ row }">
          <span class="created_at mb-0 text-sm">{{
            row.created_at | moment("DD MMM YYYY hh:mm A")
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Name" prop="first_name">
        <template v-slot="{ row }">
          <router-link :to="{ name: 'LeadDetails', params: { id: row.id }}">{{row.first_name}} {{row.last_name}}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="Status" >
        <template v-slot="{ row }">
          <span
            class="text-primary" v-if="row.type == 'enquiries'">Enquired</span>
          <span
            class="text-success" v-if="row.type == 'inspections'">Inspection</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
export default {
  name: "venue-leads",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    venueLeads: {
      required: true,
    },
  },
};
</script>

<style></style>
