<template>
  <div class="quill">
    <div :id="toolbarId">
      <div class="ql-formats">
        <button class="ql-bold" v-if="! excluded('bold')"></button>
        <button class="ql-italic" v-if="! excluded('italic')"></button>
        <button class="ql-underline" v-if="! excluded('underline')"></button>
        <button class="ql-link" v-if="! excluded('link')"></button>
        <button class="ql-blockquote" v-if="! excluded('blockquote')"></button>
        <button class="ql-code" v-if="! excluded('code')"></button>
        <button class="ql-image" v-if="! excluded('image')"></button>
        <button type="button" class="ql-list" value="ordered" v-if="! excluded('ordered-list')"></button>
        <button type="button" class="ql-list" value="bullet" v-if="! excluded('bullet-list')"></button>
      </div>
    </div>
    <div :id="editorId" :name="name" class="quill-editor" ref="editor">
    </div>
  </div>
</template>
<script>
export default {
  name: 'html-editor',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: String,
    excludedFormatters: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editor: null,
      content: null,
      lastHtmlValue: '',
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize (Quill) {
      this.editor = new Quill(`#${this.editorId}`, {
        theme: 'snow',
        modules: {
          toolbar: `#${this.toolbarId}`,
        },
      });

      if (this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }

      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on('text-change', () => {
        let html = node.innerHTML;
        if (html === '<p><br></p>') {
          html = '';
        }
        this.content = html;
        this.$emit('input', this.content);
      });
    },
    pasteHTML () {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },
    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
    excluded(item) {
      return this.excludedFormatters.indexOf(item) !== -1;
    }
  },
  async mounted () {
    let Quill = await import('quill');
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value (newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
<style scoped>
.quill-editor {
  white-space: pre;
}
.quill-editor {
  white-space: break-spaces;
}
</style>