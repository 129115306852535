<template>
  <div>
    <!-- <h6 class="heading-small text-muted mb-4 mt-4">Recommendations</h6> -->
    <el-table
      :data="venueRecommendations"
      row-key="id"
      id="venue_leads_data_table"
      header-row-class-name="thead-light"
    >
      <el-table-column label="Name" prop="created_at">
        <template v-slot="{ row }">
          <router-link :to="{ name: 'LeadDetails', params: { id: row.id }}">{{row.first_name}} {{row.last_name}}</router-link>
        </template>
      </el-table-column>
      <el-table-column label="Email" prop="email"></el-table-column>
      <el-table-column label="Phone" prop="phone"></el-table-column>
      <el-table-column label="Estimated Guests" prop="estimated_guests"></el-table-column>
      <el-table-column label="Estimated Budget" prop="estimated_budget"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
export default {
  name: "venue-recommendations",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    venueRecommendations: {
      required: true,
    },
  },
};
</script>

<style></style>
