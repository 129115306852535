<template>
    <div class="px-lg-5 py-lg-3 border-0 bg-white" style="height:420px; overflow-y: auto;">
        <div class="modal-header">
            <h5 class="modal-title text-center w-100">Create/Update Special Offers</h5>
        </div>
        <div class="modal-body py-0" v-loading="loading">
            <div class="row">
                <div class="col-lg-12">
                    <base-input label="Title" class="w-100">
                        <el-input
                            type="input"
                            placeholder="Title"
                            v-model="form.title"
                            maxlength="255"
                            class="w-100"
                        >
                        </el-input>
                        <p class="text-danger text-sm" v-if="errors.title">
                            {{ errors.title }}
                        </p>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <base-input label="Description">
                        <html-editor
                            name="editor"
                            :value="form.description"
                            :excludedFormatters="excludedHtmlFormatters"
                            @input="updateDescription"
                        ></html-editor>
                        <p
                            class="text-danger text-sm"
                            v-if="errors.description"
                        >
                            {{ errors.description }}
                        </p>
                    </base-input>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <base-input label="Event Type">
                        <el-select
                            v-model="form.for_event_type"
                            placeholder="Select event type"
                            class="w-100"
                        >
                            <el-option
                                label="Wedding"
                                value="wedding"
                            ></el-option>
                            <el-option
                                label="Corporate"
                                value="corporate"
                            ></el-option>
                            <el-option
                                label="Social"
                                value="social"
                            ></el-option>
                        </el-select>
                        <p
                            class="text-danger text-sm"
                            v-if="errors.for_event_type"
                        >
                            {{ errors.for_event_type }}
                        </p>
                    </base-input>
                </div>
				<div class="col-lg-6">
                    <base-input label="Auto enabled?">
						<el-radio-group v-model="form.is_enabled">
							<el-radio-button label="Yes"></el-radio-button>
							<el-radio-button label="No"></el-radio-button>
						</el-radio-group>
					</base-input>
  				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
                    <base-input label="Start Date">
                        <el-date-picker
                            v-model="form.startDate"
                            type="datetime"
                            placeholder="Select date and time"
                            default-time="12:00:00"
                            class="w-100"
                        >
                        </el-date-picker>
                        <p class="text-danger text-sm" v-if="errors.startDate">
                            {{ errors.startDate }}
                        </p>
                    </base-input>
                </div>
                <div class="col-lg-6">
                    <base-input label="Expiry Date">
                        <el-date-picker
                            v-model="form.expiryDate"
                            type="datetime"
                            placeholder="Select date and time"
                            default-time="12:00:00"
                            class="w-100"
                        >
                        </el-date-picker>
                        <p class="text-danger text-sm" v-if="errors.expiryDate">
                            {{ errors.expiryDate }}
                        </p>
                    </base-input>
                </div>
			</div>
            <div class="row">
                <div class="col-lg-12">
                    <base-input label="Timezone">
                        <el-select
                            v-model="form.timezone"
                            size="mini"
                            filterable
                            placeholder="Timezone"
                            class="w-100"
                        >
                            <el-option
                                v-for="timezone in allTimezones"
                                :key="timezone"
                                :label="timezone"
                                :value="timezone"
                            >
                            </el-option>
                        </el-select>
                        <p class="text-danger text-sm" v-if="errors.timezone">
                            {{ errors.timezone }}
                        </p>
                    </base-input>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-secondary" @click="$emit('cancel')">Cancel</button>
            <button class="btn btn-primary" @click="submit">Submit</button>
        </div>
    </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import VenueService from "@/services/venue.service";
import { DatePicker, Input, Notification, Option, RadioButton, RadioGroup, Select } from "element-ui";
import moment from "moment-timezone";

	export default {
		components: {
			[Select.name]: Select,
			[Option.name]: Option,
			[Input.name]: Input,
			[DatePicker.name]: DatePicker,
			[HtmlEditor.name]: HtmlEditor,
			[RadioButton.name]: RadioButton,
			[RadioGroup.name]: RadioGroup,
		},
		props: {
			venue: {
				required: true,
				type: Object,
			},
			venueSpecialOfferId: {
				required: false,
			},
		},
		data() {
			return {
				form: {
					for_event_type: "",
					title: "",
					description: "",
					startDate:"",
					expiryDate: "",
					is_enabled: 'No',
					timezone: "",
				},
				loading: false,
				errors: {},
				excludedHtmlFormatters: ["code", "image", "blockquote", 'link'],
			};
		},
		computed: {
			allTimezones() {
				return [
					"Australia/Melbourne",
					"Australia/Sydney",
					"Australia/Brisbane",
					"Europe/London",
				];
			},
		},
		async created() {
			if (this.venueSpecialOfferId) {
				this.loading = true;
				let response = await VenueService.getSpecialOffer(
					this.venue.id,
					this.venueSpecialOfferId
				);

				let data = response.data;

				this.form.for_event_type = data.for_event_type;
				this.form.title = data.title;
				this.form.description = data.description;

				const startLocalDateTime = moment
					.utc(data.start_date_utc)
					.tz(data.timezone);
				this.form.startDate = startLocalDateTime.toDate();

				const expiryLocalDateTime = moment
					.utc(data.expiry_date_utc)
					.tz(data.timezone);
				this.form.expiryDate = expiryLocalDateTime.toDate();

				this.form.timezone = data.timezone;
                this.form.is_enabled = data.is_enabled ? 'Yes' : 'No';

				this.loading = false;
			}
		},
		methods: {
			validateForm() {
				this.errors = {};

				if (!this.form.for_event_type) {
					this.errors.for_event_type = "Event type is required";
				}

				if (!this.form.title) {
					this.errors.title = "Title is required";
				}

				if (!this.form.description) {
					this.errors.description = "Description is required";
				}

				if (!this.form.expiryDate) {
					this.errors.expiryDate = "Expiry date is required";
				}

				if (!this.form.timezone) {
					this.errors.timezone = "Timezone is required";
				}

				return Object.keys(this.errors).length === 0;
			},
			async submit() {
				if (!this.validateForm()) {
					// Validation failed.
					return;
				}

				this.loading = true;
				try {
					const formattedData = {
						...this.form,
						start_date: this.form.startDate.toISOString(), // Format date for API
						expiry_date: this.form.expiryDate.toISOString(), // Format date for API
						is_enabled: this.form.is_enabled == 'Yes' ? true : false
					};

					let actionPerformed;

					if (this.venueSpecialOfferId) {
						let response = await VenueService.updateSpecialOffer(
							this.venue.id,
							this.venueSpecialOfferId,
							formattedData
						);
						actionPerformed = "updated";
					} else {
						let response = await VenueService.postSpecialOfferData(
							this.venue.id,
							formattedData
						);
						actionPerformed = "created";
					}

					// Handle successful response
					Notification.info({
						title: "Success",
						message: `Special offer ${actionPerformed} successfully`,
						type: "success",
					});

					await this.$store.dispatch(
						"venues/getVenueSpecialOffers",
						this.venue.id
					);

					this.$emit("submit");
				} catch (error) {
					console.log(error);
					// Handle error
					Notification.error({
						title: "Error",
						message:
							error.response?.data?.message ||
							`An error occurred while ${
								this.venueSpecialOfferId ? "updating" : "creating"
							} the special offer`,
						type: "error",
					});
				} finally {
					this.loading = false;
				}
			},

			updateDescription(description) {
				this.form.description = description;
			},
		},
	};
</script>
