<template>
  <card
    type="secondary"
    shadowheader-classes="bg-white pb-5"
    body-classes="px-lg-5 py-lg-5"
    class="border-0"
  >
    <template>
      <div class="text-center mb-3">
        <h5 class="modal-title" id="modelLabelTitle">{{title}}</h5>
      </div>
    </template>
    <template>
      <div class="text-center container-btn">
        <div class="block mt-5">
          <el-date-picker
            v-model="date_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="block mt-5">
          <a class="btn btn-primary btn-md" :href="downloadLink" target="blank">Export</a>
        </div>
      </div>
    </template>
  </card>
</template>
<script>
import { DatePicker } from "element-ui";
import moment from 'moment';
import buildQuery from '@/helpers/url';
export default {
  props: {
    title: {
      required: true,
    },
    export_path: {
      required: true,
    },
    params: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 6 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last Year",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last 2 Years",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date_range: [new Date().getTime() - 3600 * 1000 * 24 * 180, new Date()],
    };
  },
  components: {
    [DatePicker.name]: DatePicker,
  },
  computed: {
    downloadLink() {
      let user = JSON.parse(localStorage.getItem('user'));
      let token = user && (user.token || (user.data ? user.data.token : ''));
      let from = moment(this.date_range[0]);
      let to = moment(this.date_range[1]);
      let extras = '';
      if (this.params) {
        extras = '&' + buildQuery(this.params);
      }

      return process.env.VUE_APP_VENUECREW_API_URL + this.export_path + "?token=" 
        + token + "&source=venuecrew&from=" + from.format('YYYY-MM-DD')
        + "&to=" + to.format('YYYY-MM-DD') + extras;
    },
  },
};
</script>

<style >
.el-picker-panel__shortcut {
  white-space: nowrap;
}
.el-date-editor .el-range-separator {
  width: auto;
}
</style>