<template>
  <div>
    <div class="row">
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Enquiries</h5>
              <span class="h2 font-weight-bold mb-0">{{enquiries}}</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Viewings</h5>
              <span class="h2 font-weight-bold mb-0">{{viewings}}</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Bookings</h5>
              <span class="h2 font-weight-bold mb-0">{{bookings}}</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Conversion Rate</h5>
              <span class="h2 font-weight-bold mb-0">{{conversion_rate}}%</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Avg Sales Value </h5>
              <span class="h2 font-weight-bold mb-0">{{computed_average_sale_value}}</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-3">
        <stats-card>
          <!-- Card body -->
          <div class="row">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">Total Revenue</h5>
              <span class="h2 font-weight-bold mb-0">{{computed_total_revenue}}</span>
            </div>
            <div class="col-auto">
            </div>
          </div>
        </stats-card>
      </div>
    </div>
  </div>
</template>

<script>
import AnalyticsService from "@/services/venue_performace.service";
import { formatCurrency } from '@/util/formatCurrency';
export default {
  props: {
    venueId: {
      required: true,
    },
  },
  data() {
    return {
      storefront_pageviews: '',
      website_views: '',
      enquiries: '',
      viewings: '',
      bookings: '',
      conversion_rate: '',
      average_sale_value: '',
      total_revenue: '',
      currency: '',
    };
  },
  async created() {
    let data = await AnalyticsService.getPageviewsByVenue(this.venueId);
    data = data.data;
    this.enquiries = new Intl.NumberFormat().format(data.enquiries);
    this.viewings = new Intl.NumberFormat().format(data.viewings);
    this.bookings = new Intl.NumberFormat().format(data.bookings);
    this.conversion_rate = new Intl.NumberFormat().format(data.conversion_rate.toFixed(2));
    this.average_sale_value = data.average_sale_value;
    this.total_revenue = data.revenue;
  },
  computed: {
    venue() {
      return this.$store.getters["venues/getVenueById"](this.venueId);
    },
    computed_average_sale_value() {
      if (! this.venue.country || ! this.average_sale_value) {
        return '';
      }
      return formatCurrency(this.venue.country.toLowerCase(), this.average_sale_value);
    },
    computed_total_revenue() {
      if (! this.venue.country || ! this.total_revenue) {
        return '';
      }
      return formatCurrency(this.venue.country.toLowerCase(), this.total_revenue);
    },
  },
};
</script>