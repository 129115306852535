<template>
  <div class="content">
    <div class="header d-flex align-items-center profile-header">
      <!-- Mask -->
      <span class="mask bg-gradient-info"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12">
            <router-link :to="{ name: 'VenueDetails', params: { id: venueId }}">
              <h1 class="display-3 text-white">{{ venue.name }}</h1>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <base-header class="pb-6" v-loading="publicLinkLoading">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-white d-inline-block mb-0">
            {{ $route.meta && $route.meta.name ? $route.meta.name : $route.name }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
          <base-dropdown v-if="isTeamLead" title-classes="btn btn-secondary  btn-sm ml-5 d-inline-block " title="Venue Performance Report" id="venue_public_link_menu">
            <a class="dropdown-item regenerate" @click="regeneratePublicLink()">Re-Generate</a>
            <a class="dropdown-item view" v-if="lastGeneratedPublicLink" @click="viewLastPublicLink()">View</a>
          </base-dropdown>
          <base-dropdown  
            title-classes="btn btn-secondary  btn-sm ml-5 d-inline-block " 
            title="Login To Calendar" id="login_to_calendar" v-if="isTeamLead"
            :loading="loginToCalendarLoading"
          >
            <a  v-for="(email, index) in venueLinkedEmails" :key="index"  class="dropdown-item regenerate" @click="getCalendarLoginLink(email.email)">{{ email.email }}</a>
          </base-dropdown>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <venue-performance :venueId="venueId"/>
      <div class="row">
        <div class="col-xl-8">
          <div class="row">
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Venue Details</h3>
                    </div>
                  </div>
                  <form v-loading="loading" id="venueinformation">
                    <div v-if="venue.name">
                      <h6 class="heading-small text-muted mb-4">
                        Venue Information
                      </h6> 
                      <div class="row">
                        <div class="col-lg-12">
                          <el-alert
                            v-if="venue.is_inspection_email_contact_changed"
                            title="Inspection contact email changed!"
                            type="warning"
                            :description="`Previous inspection contact of ` + venue.previous_inspection_contact_email + ` to ` + venue.inspection_contact_email +   `. Do you want to revoke calendar access of old email?` "
                            show-icon
                            close-text="Revoke"
                            @close="revokeInspectionEmailCalendarAccess"
                            class=" mb-3">
                          </el-alert>
                          <el-alert
                            v-if="venue.is_corporate_inspection_email_contact_changed"
                            title="Inspection corporate contact email changed!"
                            type="warning"
                            :description="`Previous corporate inspection contact of ` + venue.previous_inspection_corporate_contact_email + ` to ` + venue.inspection_corporate_contact_email +   `. Do you want to revoke calendar access of old email?` "
                            show-icon
                            close-text="Revoke"
                            @close="revokeCorporateInspectionEmailCalendarAccess"
                            class=" mb-3">
                          </el-alert>
                        </div>
                      </div> 
                      
                      <div>
                        <div class="row">
                          <div class="col-lg-6">
                            <base-input
                              disabled
                              type="text"
                              label="Name"
                              id="venue_information_name"
                              placeholder="Name"
                              v-model="venue.name"
                            >
                            </base-input>
                          </div>
                          <div class="col-lg-6">
                            <base-input
                              disabled
                              type="text"
                              label="Address"
                              placeholder="Address"
                              v-model="venue.address.address"
                            >
                            </base-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <base-input
                              disabled
                              type="email"
                              label="Inspection Contact"
                              placeholder="mike@email.com"
                              v-model="venue.inspection_contact_email"
                            >
                            </base-input>
                          </div>
                          <div class="col-lg-6">
                            <base-input
                              disabled
                              type="text"
                              label="Inspection Contact Name"
                              v-model="venue.inspection_contact_name"
                            >
                            </base-input>
                          </div>
                          <div class="col-lg-12">
                            <base-input
                              disabled
                              type="text"
                              label="Description"
                              id="venue_information_description"
                              v-model="venue.description"
                            >
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
              
                </card>
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Venue Leads</h3>
                    </div>
                    <div class="col-4 text-right">
                      <a @click.stop="modalExportVenueLeads=true" class="btn btn-primary btn-sm">Export Leads</a>
                    </div>
                  </div>
                  <venue-leads :venueLeads="venueLeads"></venue-leads>
                  <div class="text-center pt-4">
                    <button @click="leadsLoadMore" v-if="shouldShowLeadLoadMore()" class="btn btn-sm btn-primary">Load more</button>
                  </div>
                </card>
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Venue Recommendations</h3>
                    </div>
                    <div class="col-4 text-right">
                      <a @click.stop="modalExportVenueRecommendations=true" class="btn btn-primary btn-sm">Export Recommendations</a>
                    </div>
                  </div>
                  <venue-recommendations :venueRecommendations="venueRecommendations"></venue-recommendations>
                  <div class="text-center pt-4">
                    <button @click="recommendationsLoadMore" v-if="shouldShowRecommendationsLoadMore()" class="btn btn-sm btn-primary">Load more</button>
                  </div>
                  <venue-emails :venueEmailLog="venueEmailLog"></venue-emails>
                  <div class="text-center pt-4">
                    <button @click="venueEmailLoadMore" v-if="shouldShowVenueEmailLog()" class="btn btn-sm btn-primary">Load more</button>
                  </div>
                </card>
              </template>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="row">
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Lost Reasons</h3>
                    </div>
                  </div>
                  <div>
                    <div v-for="(reason, index) in lostReasons" :key="index">
                      {{reason}}
                      <hr style="margin-left:-25px; margin-right: -25px; margin-top: 15px; margin-bottom: 15px"/>
                    </div>
                  </div>
                </card>
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Lose To</h3>
                    </div>
                  </div>
                  <div>
                    <div v-for="(venue, index) in topVenuesLossTo" :key="index">
                      {{venue}}
                      <hr style="margin-left:-25px; margin-right: -25px; margin-top: 15px; margin-bottom: 15px"/>
                    </div>
                  </div>
                </card>
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-6">
                      <h3 class="mb-0 " id="venue_details_heading">
                        Linked emails
                      </h3>
                    </div>
                    <div class="col-6 text-right">
                      <a @click.stop="modalAddVenueEmailLink=true" class="btn btn-primary btn-sm">Link New Email</a>
                    </div>
                  </div>
                  <div>
                    <div v-for="(email, index) in venueLinkedEmails" :key="index" class="linked-email-lists">
                      {{email.email}} 
                      <!-- badge if primary -->
                      <badge
                        v-if="email.primary"
                        rounded tag="a" class="btn-primary" href="javascript:"
                        type="primary">
                        Primary
                      </badge>
                      <!-- control group for delete and set as primary -->
                  
                      <div v-else class="controls float-right" role="group"  >
                        <button @click="setPrimaryEmail(email.email)" type="button" class="btn btn-primary btn-sm">Set as Primary</button>
                        <button @click="unlinkEmail(email.email)" type="button" class="btn btn-danger btn-sm">
                          <i class="fa fa-trash"></i> 
                        </button>
                      </div>
                      <hr style="margin-left:-25px; margin-right: -25px; margin-top: 15px; margin-bottom: 15px"/>
                    </div>
                  </div>
                </card>
                
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-6">
                      <h3 class="mb-0 " id="venue_details_heading">
                        Venue Settings
                      </h3>
                    </div>
                  </div>
                  <div>
                    <div>
                      Automatically send VPR
                      <div class="controls float-right">
                        <div class="col-2">
                          <el-switch v-model="automatedVenuePerformanceReport" @change="setAutomatedVenuePerformanceReport"></el-switch> <span class="text-sm"></span>
                        </div>
                      </div>
                      <hr style="margin-left:-25px; margin-right: -25px; margin-top: 15px; margin-bottom: 15px"/>
                    </div>
                  </div>
                </card>
                
              </template>
            </div>
            <div class="col-12">
              <template>
                <card>
                  <div slot="header" class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0" id="venue_details_heading">Special Offers</h3>
                    </div>
                    <div class="col-4 text-right">
                      <button class="btn btn-primary btn-sm" @click.stop="editSpecialOfferId= null; modalCreateSpecialOffers=true">New</button>
                    </div>
                  </div>
                  <div>
                    <div v-for="(data, index) in venueSpecialOffers" :key="index">
                      <div class="row">
                        <div class="col-10">
                          <span class="font-weight-bold">{{data.title}}</span>
                          <span class="ml-1" :class="[ data.is_enabled ? 'text-primary' : 'text-danger' ]">({{  data.is_enabled ? 'Enabled' : "Disabled" }})</span>
                        </div>
                        <div class="col-2">
                          <el-link class="el-icon-edit mr-1" @click.stop="editSpecialOfferId= data.id; modalCreateSpecialOffers=true"></el-link> 
                          <el-link class="el-icon-delete" @click="deleteVenueSpecialOffer(data.id)"></el-link> 
                        </div>
                        <div class="col-12">
                          <badge
                            rounded tag="span"
                            type="primary">
                            {{data.for_event_type}}
                          </badge>
                          <badge
                            v-if="isExpired(data.expiry_date_utc)"
                            rounded tag="span"
                            class="ml-1"
                            type="danger">
                            Expired
                          </badge>
                          <badge
                            v-else
                            rounded tag="span"
                            class="ml-1"
                            type="primary">
                            Expires {{ userExpirationDate(data.expiry_date_utc, data.timezone) }}
                          </badge>
                        </div>
                      </div>
                      <hr style="margin-left:-25px; margin-right: -25px; margin-top: 15px; margin-bottom: 15px"/>
                    </div>
                  </div>
                </card>
              </template>
            </div>
          </div>
        </div>
      </div>
      <modal
        :show.sync="modalExportVenueLeads"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-md"
      >
        <export-date-range title="Export Venue Leads" :export_path="'/v1/export/' + venueId + '/venue_leads/'"/>
      </modal>
      <modal
        :show.sync="modalExportVenueRecommendations"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-md"
      >
        <export-date-range title="Export Venue Recommendations" :export_path="'/v1/export/' + venueId + '/venue_recommendations/'"/>
      </modal>
      <modal
        :show.sync="modalVenuePublicLink"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-lg"
      >
        <div class="col-12">
          <template>
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0" id="venue_details_heading">Public Link</h3>
                </div>
              </div>
              <div v-loading="publicLinkLoading" id="public_link_text">
                {{lastGeneratedPublicLink}}
              </div>
            </card>
          </template>
        </div>
      </modal>
      <modal
        :show.sync="modalAddVenueEmailLink"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-md"
      >
        <card
          type="secondary"
          shadowheader-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0"
        >
          <template>
            <div class="text-center mb-3">
              <h5 class="modal-title" id="modelLabelTitle">Link A New Email To Venue</h5>
            </div>
          </template>
          <template> 
            <div class="row">
              <div class="col-lg-12 mt">
                <base-input 
                  type="email"
                  label="Email Address"
                  v-model="new_email_to_be_linked"
                  ref="new_email_to_be_linked"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt">
                <button class="btn btn-primary" @click="linkNewEmail">Submit</button>
              </div>
            </div>
          </template>
        </card>
      </modal>
      <modal
        v-if="modalCreateSpecialOffers"
        :show.sync="modalCreateSpecialOffers"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-lg"
      >
        <VenueSpecialOffers :venue="venue" :venueSpecialOfferId="editSpecialOfferId" @submit="modalCreateSpecialOffers = false" @cancel="modalCreateSpecialOffers = false"/>
      </modal>
    </div>
  </div>
</template>

<script>
import ReportPublicLinkService from "@/services/report_public_links.service";
import VenueService from "@/services/venue.service";
import ExportDateRange from "@/views/Widgets/Reports/ExportDateRange";
import VenueEmails from '@/views/Widgets/Venues/VenueEmails';
import VenueLeads from '@/views/Widgets/Venues/VenueLeads';
import VenuePerformance from '@/views/Widgets/Venues/VenuePerformance';
import VenueRecommendations from '@/views/Widgets/Venues/VenueRecommendations';
import VenueSpecialOffers from '@/views/Widgets/Venues/VenueSpecialOffers';
import {
    Alert,
    Link,
    MessageBox,
    Switch,
    Table,
    TableColumn
} from "element-ui";
import moment from 'moment';
import { mapState } from "vuex";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Alert.name]: Alert,
    VenueLeads,
    VenueRecommendations,
    VenueEmails,
    VenuePerformance,
    VenueSpecialOffers,
    ExportDateRange,
    [Switch.name]: Switch,
    [Link.name]: Link
  },
  data() {
    return {
      venueId: parseInt(this.$route.params.id),
      dateOptions: {
        format: "YYYY-MM-DD",
      },
      venueLeadsPage: 1,
      venueEmailLogsPage : 1,
      venueRecommendationsPage: 1,
      loading: true,
      showLoadMore: true,
      modalExportVenueLeads: false,
      modalExportVenueRecommendations: false,
      modalVenuePublicLink: false,
      lastGeneratedPublicLink: '',
      publicLinkLoading: false,
      loginToCalendarLoading: false,
      modalAddVenueEmailLink: false,
      modalCreateSpecialOffers: false,
      new_email_to_be_linked: '',
      automatedVenuePerformanceReport:false,
      editSpecialOfferId: ''
    };
  },
  async created() {
    await this.$store.dispatch("venues/getSingle", { id: this.venueId });
    await this.$store.dispatch("venues/getTopVenuesLossTo", { venue_id: this.venueId });
    await this.$store.dispatch("venues/getLostReasons", { venue_id: this.venueId });
    await this.$store.dispatch("venues/getVenueLinkedEmails", { venue_id: this.venueId });
    await this.$store.dispatch("venues/getVenueSpecialOffers", this.venueId);
    await this.loadVenueLeads(1);
    await this.loadVenueEmailLogs(1);
    await this.loadVenueRecommendations(1);
    this.loading = false;
  },
  async mounted() {
    let link = await ReportPublicLinkService.getVenueReportPublicLink(this.venueId);
    this.lastGeneratedPublicLink = link.data;
    this.automatedVenuePerformanceReport = this.venue.automated_venue_performance_report ? true : false;
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdministrator"];
    },
    isTeamLead() {
      return this.$store.getters["auth/isTeamlead"];
    },
    venue() {
      return this.$store.getters["venues/getVenueById"](this.venueId);
    },
    venueLeads() {
      return this.$store.getters["venues/getVenueLeads"](this.venueId);
    },
    venueRecommendations() {
      return this.$store.getters["venues/getVenueRecommendations"](this.venueId);
    },
    getVenueLeadsTotal() {
      return this.$store.getters["venues/getVenueLeadsTotal"](this.venueId);
    },
    venueEmailLog() {
      return this.$store.getters["venues/getVenueEmailLogs"](this.venueId);
    },
    getVenueEmailLogTotal() {
      return this.$store.getters["venues/getVenueEmailLogTotal"](this.venueId);
    },
    getVenueRecommendationsTotal() {
      return this.$store.getters["venues/getVenueRecommendationsTotal"](this.venueId);
    },
    topVenuesLossTo() {
      return this.$store.getters["venues/getTopVenuesLossTo"](this.venueId);
    },
    lostReasons() {
      return this.$store.getters["venues/getLostReasons"](this.venueId);
    },
    venueLinkedEmails() {
      return this.$store.getters["venues/getVenueLinkedEmails"](this.venueId);
    },
    venueSpecialOffers() {
      return this.$store.getters["venues/getVenueSpecialOffers"](this.venueId);
    },
    ...mapState("country/", {
      country: (state) => state.country,
    }),
  },
  methods: {
    revokeInspectionEmailCalendarAccess() {
      MessageBox.confirm('Are you sure?', 'Revoke old inspection calendar access.', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await VenueService.revokeInspectionEmailCalendarAccess(this.venueId, false).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.$store.dispatch("venues/getSingle", { id: this.venueId });
        this.loading = false;
      });
    },
    revokeCorporateInspectionEmailCalendarAccess() {
      MessageBox.confirm('Are you sure?', 'Revoke old inspection calendar access.', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await VenueService.revokeInspectionEmailCalendarAccess(this.venueId, true).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.$store.dispatch("venues/getSingle", { id: this.venueId });
        this.loading = false;
      });
    },
    goToLead(id) {
      this.$router.push({ name: "LeadDetails", params: { id } });
    },
    async loadVenueLeads(page) {
      await this.$store.dispatch("venues/getVenueLeads", {venueId: this.venueId, page});
    },
    async loadVenueEmailLogs(page) {
      await this.$store.dispatch("venues/getVenueEmailLogs", {venueId: this.venueId, page});
    },
    async loadVenueRecommendations(page) {
      await this.$store.dispatch("venues/getVenueRecommendations", {venueId: this.venueId, page});
    },
    shouldShowLeadLoadMore() {
      if (this.getVenueLeadsTotal <= (this.venueLeadsPage * 50) ) {
        return false;
      }
      return true;
    },
    shouldShowVenueEmailLog() {
      if (this.getVenueEmailLogTotal <= (this.venueEmailLogsPage * 50) ) {
        return false;
      }
      return true;
    },
    async venueEmailLoadMore() {
      this.loading = true;
      this.venueEmailLogsPage = this.venueEmailLogsPage + 1;
      await this.loadVenueEmailLogs(this.venueEmailLogsPage);
      this.loading = false;
    },
    async leadsLoadMore() {
      this.loading = true;
      this.venueLeadsPage = this.venueLeadsPage + 1;
      await this.loadVenueLeads(this.venueLeadsPage);
      this.loading = false;
    },
    shouldShowRecommendationsLoadMore() {
      if (this.getVenueRecommendationsTotal <= (this.venueRecommendationsPage * 50) ) {
        return false;
      }
      return true;
    },
    async recommendationsLoadMore() {
      this.loading = true;
      this.venueRecommendationsPage = this.venueRecommendationsPage + 1;
      await this.loadVenueRecommendations(this.venueLeadsPage);
      this.loading = false;
    },
    async regeneratePublicLink(){
      this.publicLinkLoading = true;
      // this.modalVenuePublicLink = true;

      let link = await ReportPublicLinkService.generateVenueReportPublicLink(this.venueId);
      this.lastGeneratedPublicLink = link.data;
      var id = (new Date()).getTime();
      window.open(this.lastGeneratedPublicLink , '_blank', id);
      this.publicLinkLoading = false;
    },
    async viewLastPublicLink() {
      this.publicLinkLoading = true;
      // this.modalVenuePublicLink = true;
      let link = await ReportPublicLinkService.getVenueReportPublicLink(this.venueId);
      this.lastGeneratedPublicLink = link.data;
      if (!this.lastGeneratedPublicLink) {
        this.publicLinkLoading = false;
        return;
      }
      var id = (new Date()).getTime();
      window.open(this.lastGeneratedPublicLink , '_blank', id);
      this.publicLinkLoading = false;
    },
    async getCalendarLoginLink(email) {
      this.loginToCalendarLoading = true;
      let data = await VenueService.getVenueCalendarLoginLink(this.venueId, email);
      this.loginToCalendarLoading = false;
      var id = (new Date()).getTime();
      window.open(data.data.link , '_blank', id);
    },
    async linkNewEmail() {
      var re = /\S+@\S+\.\S+/;
      if (!re.test(this.new_email_to_be_linked)) {
        this.$store.dispatch('alert/error', 'Please enter a valid email address.', { root: true });
        return;
      } 
      this.loading = true;
      await VenueService.linkNewEmail(this.venueId, this.new_email_to_be_linked).catch((error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      });
      await this.$store.dispatch("venues/getVenueLinkedEmails", { venue_id: this.venueId });
      this.loading = false;
      this.modalAddVenueEmailLink = false;
      this.new_email_to_be_linked = '';
    },
    async setPrimaryEmail(email) {
      this.loading = true;
      await VenueService.setPrimaryEmail(this.venueId, email).catch((error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      });
      await this.$store.dispatch("venues/getVenueLinkedEmails", { venue_id: this.venueId });
      this.loading = false;
    },
    async unlinkEmail(email) {
      this.loading = true;
      await VenueService.unlinkEmail(this.venueId, email).catch((error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      });
      await this.$store.dispatch("venues/getVenueLinkedEmails", { venue_id: this.venueId });
      this.loading = false;
    },
    async setAutomatedVenuePerformanceReport() {
      await VenueService.setAutomatedPerformanaceReport(this.venueId, this.automatedVenuePerformanceReport).catch((error) => {
        this.$store.dispatch('alert/error', error, { root: true });
      });
    },
    deleteVenueSpecialOffer(offerId) {
      MessageBox.confirm('Are you sure to delete this offer?', 'Cancel', {
        distinguishCancelAndClose: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async () => {
        this.loading = true;
        await VenueService.deleteSpecialOffer(
          this.venueId,
          offerId
        ).catch((error) => {
          this.$store.dispatch('alert/error', error, { root: true });
        });
        await this.$store.dispatch("venues/getVenueSpecialOffers", this.venueId);
        this.loading = false;
      });
    },
    isExpired(expiryDateUtc) {
      const currentDate = moment.utc(); // Get the current date in UTC
      const expiryDate = moment.utc(expiryDateUtc); // Convert the expiry date to UTC
      return expiryDate.isBefore(currentDate); // Check if the expiry date is before the current date
    },
    userExpirationDate(expiryDateUtc, timezone) {
      // Convert the expiry date to the user's timezone
      const expiryDateInUserTimezone = moment.utc(expiryDateUtc).tz(timezone);
      return expiryDateInUserTimezone.fromNow();
    }
  },
};
</script>

<style scoped>
/* .btn {
  color: white;
}
.btn:hover {
  color: white;
} */
.linked-email-lists:hover .controls {
  display: block;
}
.linked-email-lists .controls {
  display: none;
}
</style>

<style>
.el-alert__closebtn.is-customed {
  color: #e30000 !important;
  font-weight: bold;
}
</style>